import * as Papa from 'papaparse';
import React, { useState } from 'react';
import { useEffect } from 'react';

import AgentCreation from '../components/createAgent';
import Upload from '../components/upload';

function UploadFile() {

  const [agent, setAgent] = useState( {} );
  const [failed, setFailed] = useState( [] );
  const [success, setSuccess] = useState( [] );

  function fileAdded( files ){
    const file = files[0];
    console.log( file );
    const reader = new FileReader();

    if( file ){
      reader.readAsText( file );

      reader.onload = async function() {
        const agents = Papa.parse( reader.result, {
          fastMode: true,
          header: true
        } ).data;

        for ( let index = 0; index < agents.length; index++ ) {
          const element = agents[index];
          await new Promise( r => setTimeout( r, 2000 ) );
          setAgent( element );
        }
      };

      reader.onerror = function() {
        console.log( reader.error );
      };
    }
  }

  function postSignUp( result ){
    if( result.error ){
      const emails = [...failed];
      emails.push(
        {
          ...result,
          email: result.email,
          error: result.error
        }
      );
      setFailed( emails );
    } else {
      setSuccess( success.push( result ) );
    }
  }

  useEffect( () => {
    console.log( agent );
  }, [agent] );

  return(
    <>
      <AgentCreation signUpStatus={postSignUp} email={agent.email} password={agent.password} firstName={agent.first_name} lastName={agent.last_name} company={agent.company} phoneNo={agent.phone_no}/>

      <Upload fileAdded={fileAdded} acceptedTypes={'.csv'}/>

      {
        success.map( ( { email }, i ) => ( <p key={i}>
          Created profile for {email}
        </p> ) )
      }

      {
        failed.map( ( { email }, i ) => ( <p key={i}>
          Sign up failed for {email}
        </p> ) )
      }
    </>
  );
}

export default UploadFile;
