import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';

import { PROD_SAVE_AGENT,SAVE_AGENT } from '../helpers/queries';

function AgentCreation( { email, password, firstName, lastName, company, phoneNo, signUpStatus } ) {

  const [createAgent, { data, loading, error }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_SAVE_AGENT : SAVE_AGENT );

  function setUpAgent(){
    console.log( email, 'email' );
    if( email ){
      const data = {
        client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
        email,
        password,
        connection: 'Username-Password-Authentication',
        given_name: firstName,
        family_name: lastName,
        name: `${firstName} ${lastName}`,
        nickname:  firstName,
        app_metadata: {},
        user_metadata: { company_name: company, phoneNo }
      };
      let isError = false;
      fetch( process.env.GATSBY_AUTH0_URL + '/dbconnections/signup', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify( data )
      } ).catch( error => { console.log( error ); signUpStatus( { error, email } ); } )
        .then( resp => {
          if( resp.status > 200 ){
            isError = true;
          }
          return resp.json();
        } )
        .then( ( data ) => {
          console.log( data );
          if( !isError ){
            signUpStatus( { success: 'Okay', email } );
            createAgent( {
              variables: {
                auth0_id: 'auth0|' + data._id,
                first_name: firstName || '',
                last_name: lastName || '',
                email,
                phone_no: phoneNo,
                company,
                full_name: `${firstName || ''} ${lastName || ''}`
              }
            } );
            fetch( `${process.env.GATSBY_API_BASE_URL}/email`, {
              method: 'POST',
              body: JSON.stringify( {
                name: `${firstName || ''} ${lastName || ''}`,
                email
              } ),
              headers: {
                'content-type': 'application/json'
              }
            } );
          } else {
            signUpStatus( { error: true, ...data, email } );
          }
        } );
    }
  }

  useEffect( setUpAgent, [email] );

  return(
    <>
    </>
  );
}

export default AgentCreation;
